// @flow
import React from 'react';
import styled from 'styled-components';
import profileImg from '../profile.jpg';
const Header = () => {
  return (
    <Container>
      <Logo src={profileImg} />
      <Title>Maximiliano Techera</Title>
      <Subtitle>Design + Code + Architecture</Subtitle>
    </Container>
  );
};
const Container = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;
const Logo = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  margin: auto;
  display: block;
  border-radius: 50%;
  margin-bottom: 8px;
`;
const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
`;

const Subtitle = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  opacity: 0.5;
`;

export default Header;
