import React from "react";
import styled from "styled-components";

const Section = () => {
  return (
    <Container>
      <Inner>
        <Title>Projects</Title>
        <Content lead>
          I've worked on several projects, both during my time as a freelancer and working with companies. While at
          Beta, I worked with several apps such as Top Nine, Want, Throwback Stories, and others.
        </Content>
      </Inner>
    </Container>
  );
};
const Inner = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  width: 85%;
`;
const Container = styled.div`
  width: 100%;
  padding: 3em 0;
`;

const Title = styled.h1`
  font-size: 1.583333em;
  text-transform: uppercase;
  margin-bottom: 12px;
  position: relative;
  margin-top: 0;
  &:before {
    position: absolute;
    background: currentColor;
    width: 32px;
    height: 5px;
    top: -0.6em;
    left: 0;
    content: "";
  }
`;
const Content = styled.p`
  font-size: 1em;
  margin-bottom: 1.5em;
  ${props =>
    props.lead &&
    `
  font-size: 1.333em;
  line-height: 1.4em;
  `}
`;
export default Section;
