import React, { useState } from 'react';
import styled from 'styled-components';

const Section = () => {
  const [data, setData] = useState({ from: '', body: '' });
  console.log('DATA', { data });
  return (
    <Container>
      <Inner>
        <Align>
          <Title>GET IN TOUCH</Title>
          <Content>
            Interested in working together, brewing an espresso or having me speak at your event?
            Great! Get in touch, I'm friendly.
          </Content>
          <div>
            <Input
              placeholder="Email"
              onChange={({ target: { value } }) => setData(state => ({ ...state, from: value }))}
            />
          </div>
          <Textarea
            rows={4}
            type="textarea"
            placeholder="Messsage"
            onChange={({ target: { value } }) => setData(state => ({ ...state, body: value }))}
          />
          <div>
            <a
              target="_blank"
              href={`mailto:maxi.techerag@gmail.com?from=${data.from}&body=${
                data.body
              }&subject=Lets get in touch`}
            >
              <Button value="Submit" invert />
            </a>
          </div>
        </Align>
      </Inner>
    </Container>
  );
};
const Align = styled.div`
  width: 66.666%;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
`;
const Inner = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  width: 85%;
`;
const Container = styled.div`
  width: 100%;
  padding: 3em 0;
  background: black;
  color: white;
`;

const Button = styled.input.attrs({ type: 'submit' })`
  background: white;
  border: 1px solid black;
  outline: none;
  border-radius: 5px;
  padding: 1em;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 32px;
  width: auto;
  display: inline-block;
  cursor: pointer;
  margin-right: 16px;
  transition: 0.3s;
  ${props =>
    props.invert &&
    `
    &:not(:hover){
      background:black;
      color:white;
      border-color:white;
    }
  
  
  `}
`;

const Title = styled.h1`
  font-size: 1.583333em;
  text-transform: uppercase;
  margin-bottom: 12px;
  position: relative;
  margin-top: 0;
  &:before {
    position: absolute;
    background: currentColor;
    width: 32px;
    height: 5px;
    top: -0.6em;
    left: 0;
    content: '';
  }
`;
const Content = styled.p`
  font-size: 1em;
  margin-bottom: 1.5em;
  ${props =>
    props.lead &&
    `
  font-size: 1.333em;
  line-height: 1.4em;
  `}
`;

const Input = styled.input`
  margin-bottom: 16px;
  max-width: 300px;
  font-size: 20px;
  background: transparent;
  webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding: 12px 0 6px 0;
  outline: none;
  color: white;
`;
const Textarea = styled.textarea`
  margin-bottom: 16px;
  max-width: 300px;
  font-size: 20px;
  background: transparent;
  webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding: 12px 0 6px 0;
  outline: none;
  color: white;
  overflow: hidden;
  resize: none;
`;
export default Section;
