import React from "react";
import styled from "styled-components";

const Section = () => {
  return (
    <Container>
      <Inner>
        <LinkContainer>
          <Link target="_blank"href="https://twitter.com/maxtechera">T</Link>
          <Link target="_blank"href="http://github.com/maxtechera">G</Link>
          <Link target="_blank"href="https://www.instagram.com/maxi.techera/">I</Link>
          <Link target="_blank"href="https://www.facebook.com/maxi.techera93">F</Link>
        </LinkContainer>
        <Content>Maximiliano Techera ©2019</Content>
      </Inner>
    </Container>
  );
};

const LinkContainer = styled.ul`
  text-align: center;
  margin-bottom: 16px;
`;
const Link = styled.a`
  display: inline-block;
  color: white;
  margin-right: 8px;
  border-radius: 50%;
  border: 2px solid white;
  font-size: 18px;
  line-height: 38px;
  height: 40px;
  width: 40px;
  text-align: center;
  text-decoration: none;
  &:hover {
    color: black;
    background: white;
  }
`;

const Inner = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  width: 85%;
`;
const Container = styled.div`
  width: 100%;
  padding: 3em 0;
  background: black;
  color: white;
`;

const Content = styled.p`
  font-size: 12px;
  text-align: center;
`;

export default Section;
