import React from 'react';
import styled from 'styled-components';
import profileImage from '../profile.jpg';
const Section = () => {
  return (
    <Container>
      <Inner>
        <Half>
          <Title>About me</Title>
          <Content lead>
            Hi I'm Max! I'm an engineer, gamer, tinkerer and coffee enthusiast living in Uruguay. I
            like solving unusual and difficult problems. I help companies add value through user
            centered design.
          </Content>
          <Content>I'm currently working with Javascript and a bunch of React!</Content>
          <Button onClick={() => window.scrollTo(0, document.body.scrollHeight)}>
            {' '}
            Get in touch
          </Button>
        </Half>
        <RightHalf>
          <Logo src={profileImage} />
        </RightHalf>
      </Inner>
    </Container>
  );
};
const Inner = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  width: 85%;
`;
const Container = styled.div`
  width: 100%;
  padding: 3em 0;
  margin-top: 3em;
  @media only screen and (max-width: 480px) {
    margin-top: 0;
  }
`;

const Half = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 66%;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
const RightHalf = styled.div`
  display: inline-block;
  vertical-align: top;
  padding-left: 64px;
  width: 33%;
`;
const Logo = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-bottom: 8px;
  display: inline-block;
  vertical-align: top;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;
const Title = styled.h1`
  font-size: 1.583333em;
  text-transform: uppercase;
  margin-bottom: 12px;
  position: relative;
  margin-top: 0;
  &:before {
    position: absolute;
    background: currentColor;
    width: 32px;
    height: 5px;
    top: -0.6em;
    left: 0;
    content: '';
  }
`;
const Content = styled.p`
  font-size: 1em;
  margin-bottom: 1.5em;
  ${props =>
    props.lead &&
    `
  font-size: 1.333em;
  line-height: 1.4em;
  `}
`;
const Button = styled.button`
  background: white;
  border: 1px solid black;
  outline: none;
  border-radius: 5px;
  padding: 1em;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
  transition: 0.3s;
  &:hover {
    background: black;
    color: white;
    border-color: white;
  }
`;
export default Section;
