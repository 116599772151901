// @flow
import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Header from './Header';

const conversations = {
  'hi-again': {
    id: 'hi-again',
    messages: [
      { message: 'There you are again!' },
      { message: 'Still want to know more or get in touch?' },
      {
        options: [
          { id: 'hi-again-2', message: 'Tell me more!' },
          { id: 'get-in-touch', message: 'Get in touch!' },
        ],
      },
    ],
  },
  hi: {
    id: 'hi',
    messages: [
      { message: 'Hi there!' },
      { message: "I'm Maximiliano Techera, a full stack engineer living in Uruguay." },
      { message: 'Want to know more or get in touch?' },
      {
        options: [
          { id: 'tell-me-more', message: 'Tell me more!' },
          { id: 'get-in-touch', message: 'Get in touch!' },
        ],
      },
    ],
  },
  'tell-me-more': {
    id: 'tell-me-more',
    messages: [
      { answer: true, message: 'Tell me more!' },
      { message: 'I feel honored!' },
      {
        message: `I like to write and speak about UX development and feel fortunate to have worked with incredibly talented people on amazing projects.`,
      },
      { message: "Oh, I'm teaching a course at ORT University too 🤓!!" },
      {
        options: [
          { id: 'projects', message: 'Which projects?' },
          { id: 'teach', message: 'You teach?!' },
        ],
      },
    ],
  },
  'tell-me-more-again-2': {
    id: 'tell-me-more-again-2',
    messages: [
      { answer: true, message: 'Tell me more!' },
      {
        message:
          'Ok cool! I know this might get repetitive but this is how I learnt "introducing myself"',
      },
      {
        message: `As mentioned, I like to write and speak about UX development and feel fortunate to have worked with incredibly talented people on amazing projects.`,
      },
      { message: "Oh, I'm teaching a course at ORT University too 🤓!!" },
      {
        options: [
          { id: 'projects', message: 'Which projects?' },
          { id: 'teach', message: 'You teach?!' },
        ],
      },
    ],
  },
  'hi-again-2': {
    id: 'hi-again-2',
    messages: [
      { answer: true, message: 'Tell me more!' },
      { message: "Ok cool! It's great to meet you!" },
      {
        message:
          "First let me introduce myself quick... <br> I'm Maximiliano Techera, a full stack engineer living in Uruguay.",
      },
      { message: 'Want to know more or get in touch?' },
      {
        options: [
          { id: 'tell-me-more-again-2', message: 'Tell me more!' },
          { id: 'get-in-touch', message: 'Get in touch!' },
        ],
      },
    ],
  },
  projects: {
    id: 'projects',
    messages: [
      { answer: true, message: 'Which projects?' },
      {
        message: 'Here are some of the projects I worked on staff and as an independent freelancer',
      },
      {
        message: `
      - Top Nine <br>
      - Want <br>
      - Throwback Stories <br>
      - Cryptofacil <br>
      `,
      },
      { options: [{ id: 'what-did-you', message: 'Cool what did you work on?' }] },
    ],
  },

  teach: {
    id: 'teach',
    messages: [
      { answer: true, message: 'You teach?!' },
      {
        message:
          "Yes! I'm a strong believer that teaching is the best way to learn. This semester is all about UX design, JS, React and React Native!",
      },
      {
        options: [
          { id: 'speaking', message: 'What about speaking?' },
          { id: 'get-in-touch', message: 'Get in touch!' },
        ],
      },
    ],
  },
  'get-in-touch': {
    id: 'get-in-touch',
    messages: [
      { answer: true, message: 'Get in touch!' },
      { message: "Great! I'm looking forward to it!" },
      {
        message: `Let me know what you would like to talk about and I'll get back at you asap.`,
      },
      {
        message:
          "Drop me an email via <a target='_blank' href='mailto:maxi.techerag@gmail.com'>maxi.techerag@gmail.com</a>",
      },
      { message: 'Well, got to get going! Feel free to scroll through my website!' },
      {
        options: {
          id: 'message',
          input: 'message',
        },
      },
    ],
  },
  speaking: {
    id: 'speaking',
    messages: [
      { answer: true, message: 'What about speaking?' },
      { message: "I'd love to speak at your event!" },
      {
        message: `Let me know whats on your mind and I'll get back at you asap.`,
      },
      {
        message:
          "Drop me an email via <a target='_blank' href='mailto:maxi.techerag@gmail.com'>maxi.techerag@gmail.com</a>",
      },
      { message: 'Well, got to get going! Feel free to scroll through my website!' },
      {
        options: {
          id: 'message',
          input: 'message',
        },
      },
    ],
  },
  'get-in-touch-coffee': {
    id: 'get-in-touch-coffee',
    messages: [
      { answer: true, message: 'Cool! ☕️' },
      { message: "Great! I'm looking forward to it!" },
      {
        message: `Let me know what you would like to talk about and I'll get back at you asap`,
      },
      {
        message:
          "Drop me an email via <a target='_blank' href='mailto:maxi.techerag@gmail.com'>maxi.techerag@gmail.com</a>",
      },
      { message: 'Well, got to get going! Feel free to scroll through my website!' },
      {
        options: {
          id: 'message',
          input: 'message',
        },
      },
    ],
  },
  'get-in-touch-ok': {
    id: 'get-in-touch-ok',
    messages: [
      { answer: true, message: 'Ok, sure!' },
      { message: "Great! I'm looking forward to it!" },
      {
        message: `Let me know what you would like to talk about and I'll get back at you asap`,
      },
      {
        message:
          "Drop me an email via <a target='_blank' href='mailto:maxi.techerag@gmail.com'>maxi.techerag@gmail.com</a>",
      },
      { message: 'Well, got to get going! Feel free to scroll through my website!' },
      {
        options: {
          id: 'message',
          input: 'message',
        },
      },
    ],
  },
  'what-did-you': {
    id: 'what-did-you',
    messages: [
      { answer: true, message: 'Cool what did you work on?' },
      { message: "I'm happy to talk about it over coffee. <br> What do you think?" },

      {
        options: [
          {
            message: 'Cool! ☕️',
            id: 'coffee',
          },
          {
            message: 'Nah... 😑️',
            id: 'not-coffee',
          },
        ],
      },
    ],
  },
  'not-coffee': {
    id: 'not-coffee',
    messages: [
      { answer: true, message: 'Nah... 😑️' },
      { message: 'Ok... I understand. Not everybody likes coffee. How about tea then?' },

      {
        options: [
          {
            message: 'Ok, sure!️',
            id: 'get-in-touch-ok',
          },
          {
            message: 'Nah... 😑️',
            id: 'more-info',
          },
        ],
      },
    ],
  },
  'more-info': {
    id: 'more-info',
    messages: [
      { answer: true, message: 'Nah... 😑️' },
      { message: 'Ok...' },
      {
        message: 'I have completed some pretty cool projects using GraphQL, React and React Native',
      },
      { message: 'All done with the Node microservices architecture of your dreams' },
      { message: 'Cleanly deployed in AWS too!' },
      {
        message: 'Well enough bragging, got to get going! Feel free to scroll through my website!',
      },
    ],
  },
};

// { message: "Tell me more!", answer: true },
const MESSAGE_DELAY = 1000;
const getDelay = message =>
  Math.max(MESSAGE_DELAY, message && message.length ? message.length * 0.045 * 500 : 0);

const Chat = () => {
  const visited = localStorage.getItem('visited');

  const [paths, setPaths] = useState([localStorage.getItem('visited') ? 'hi-again' : 'hi']);
  localStorage.setItem('visited', true);
  const [displayed, setDisplayed] = useState(0);
  useEffect(() => {
    if (displayed < messages.length - 1) {
      setTimeout(
        () => setDisplayed(displayed + 1),
        messages[displayed].options ? 0 : getDelay(messages[displayed].message) + 500,
      );
    }
  }, [displayed, paths]);

  const messages = paths.reduce((accum, path) => [...accum, ...conversations[path].messages], []);
  const displayedMessages = messages.filter((msg, idx) => idx <= displayed);
  const optionClick = id => () => {
    // setDelay(0);
    setPaths([...paths, id]);
  };
  const lastMessage = displayedMessages[displayedMessages.length - 1];

  return (
    <Overflow>
      <Container>
        <Header />
        <List>
          {displayedMessages.map((msg, idx) =>
            !msg.message ? null : <Message key={msg.message + '-' + idx} message={msg} />,
          )}
        </List>
      </Container>
      {lastMessage.options && lastMessage.options.length ? (
        <Options>
          {lastMessage.options.map(({ id, message }, idx) => (
            <Text
              key={'message-' + idx}
              onClick={optionClick(id)}
              option
              dangerouslySetInnerHTML={{ __html: message }}
            />
          ))}
        </Options>
      ) : null}
      <ScrollMessage>Not feeling like talking? Scroll down to see my web!</ScrollMessage>
    </Overflow>
  );
};

const Overflow = styled.div`
  background: #fafafa;
  position: relative;
  border-bottom: 2px solid #eee;
  padding-bottom: 72px;
`;
const Container = styled.div`
  position: relative;
  min-height: calc(100vh - 110px);
  padding-bottom: 118px;
  width: 90%;
  max-width: 700px;
  margin: auto;
  margin-top: 1em;
`;
const fadeIn = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
  `;
const List = styled.ol`
  list-style: none;
  padding-inline-start: 0px;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards linear;
`;
const slideInLeft = keyframes`
  0% {transform: translateX(-20%);opacity: 0;}
  100% {transform: translateX(0px);opacity: 1;}
`;
const slideInRight = keyframes`
  0% {transform: translateX(20%);opacity: 0;}
  100% {transform: translateX(0px);opacity: 1;}
`;
const slideInBottom = keyframes`
  0% {transform: translateY(50px);opacity: 0;}
  100% {transform: translateY(0px);opacity: 1;}
`;
const Text = styled.div`
  min-width: 44px;
  min-height: 12px;
  max-width: 90%;
  font-size: 19px;
  font-family: Helvetica;
  padding: 8px 16px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
  border-radius: 5px;
  display: inline-block;
  background: white;
  opacity: 0;
  min-height: 38px;
  transition-property: max-height, max-width, color;
  transition-duration: .7s, .5s, .4s;
  transition-timing-function: ease-out, ease-out, ease;
  transition-delay: .4s, 0s, .5s; 
  max-height: 300px;
  animation:${slideInLeft} 0.4s linear forwards;
  animation-delay: 0.7s;


  ${props =>
    props.loading &&
    `
    max-width: 40px;
    max-height: calc(1em + 16px);
    white-space:none;
    :before {
      transition: .3s;
      position:absolute;
      content:'...';
      color:${props.animated ? 'transparent' : 'black'};
    }
  `}

  ${props =>
    props.animated
      ? `
    color:${props.answer ? 'white' : 'black'};
    
  `
      : `color:transparent; a{color:transparent;}`}

      ${props =>
        props.answer &&
        css`
          float: right;
          color: white;
          background: #3c4859;
          animation: ${slideInRight} 0.3s linear forwards;
        `}
      ${props =>
        props.option &&
        `
        background: #3c4859;
        color: white;
        cursor:pointer;
        transition: .3s;
        
        &:hover{
          transform:scale(1.1);
        }
      `}
`;

const MessageContainer = styled.li`
  overflow: hidden;
`;
const useLoading = (initialLoading, delay) => {
  const [loading, setLoading] = useState(initialLoading);
  const [animated, setAnimated] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setTimeout(() => setAnimated(true), 200);
    }, delay);
  });
  return [loading, animated];
};
const Message = ({ message, delay = MESSAGE_DELAY }) => {
  if (!message) return null;
  const [loading, animated] = useLoading(true, message.message ? getDelay(message.message) : 0);
  return (
    <MessageContainer>
      <Text
        answer={message.answer}
        loading={loading && !message.answer}
        animated={animated}
        dangerouslySetInnerHTML={{ __html: loading && !message.answer ? '' : `${message.message}` }}
      />
    </MessageContainer>
  );
};

const Options = styled.div`
  width: 100%;
  position: absolute;
  bottom: 128px;
  margin: 16px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Text} {
    margin: 0 8px;
    animation-delay: 2s;
    animation ${slideInBottom}  0.5s linear forwards;
  }
`;
const fadeOut = keyframes`
  100% {opacity: 0;}
`;

const ScrollMessage = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 25px;
  opacity: 0.5;
  bottom: 16px;
  animation: ${fadeOut} 0.5s linear forwards;
  animation-delay: 3s;
`;

export default Chat;
